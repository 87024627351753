import Immutable from 'immutable';

// Actions
const OPEN = 'skylark/modals/OPEN';
const CLOSE = 'skylark/modals/CLOSE';

// const LOCATION_CHANGED = '@@router/LOCATION_CHANGE';
const SIGNIN_SUCCESS = 'skylark/authentication/SIGNIN_SUCCESS';
const SIGNUP_SUCCESS = 'skylark/authentication/SIGNUP_SUCCESS';

export const initialState = Immutable.fromJS({
  show: false,
  animation: 'zoom-in',
});

const modalOpened = (state, action) => {
  const { animation, source, ...props } = action.payload.modalProps;
  return state
    .set('modalType', action.payload.modalType)
    .set('animation', animation || state.get('animation'))
    .set('show', true)
    .set('source', source)
    .set('component', props.component)
    .set('props', props);
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return modalOpened(state, action);
    case CLOSE:
      return state.set('show', false);
    // case LOCATION_CHANGED:
    //   return state.set('show', false);
    case SIGNIN_SUCCESS:
      return initialState;
    case SIGNUP_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

// Action Creators
export function openModal(modalType, props = {}) {
  return {
    type: OPEN,
    payload: {
      modalType,
      modalComponent: props.component,
      modalProps: props,
    },
  };
}

export function closeModal() {
  return {
    type: CLOSE,
  };
}
