import * as React from 'react';
import isMobile from 'ismobilejs';
import Context from './Context';

const Provider = ({ children, userAgent }) => {
  const isMobileDevice = isMobile(userAgent);
  const value = {
    phone: isMobileDevice.phone,
    tablet: isMobileDevice.tablet,
    any: isMobileDevice.any,
    apple: isMobileDevice.apple.device,
    android: isMobileDevice.android.device,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default Provider;
