import { useContext } from 'react';
import Context from './Context';
import Provider from './Provider';

const useIsMobile = () => useContext(Context);

export const UserAgentProvider = Provider;
export const UserAgentContext = Context;
export const UserAgentConsumer = Context.Consumer;
export default useIsMobile;
