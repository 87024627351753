const devMode = false;
const apiUrl = 'https://api.skylark.com';
const localApiUrl = devMode ? 'http://api.lark.local' : apiUrl;
const gatewayApiUrl = 'https://gateway.skylark.com/api';
const gatewayExUrl = 'https://gateway.skylark.com/ex';
// const gatewayExUrl = 'https://sabre-inventory.skylark.com';
const bump = true;

const contentEndpoint = {
  development: {
    CONFIG_API_ENDPOINT: `${localApiUrl}/v4`,
    CONTENT_API_ENDPOINT: `${localApiUrl}/v3`,
    DEALS_API_ENDPOINT: `${localApiUrl}/v3`,
    COLLECTIONS_API_ENDPOINT: `${localApiUrl}/v4`,
    HOTEL_API_ENDPOINT: `${localApiUrl}/v5`,
    SEARCH_API_ENDPOINT: `${localApiUrl}/v4`,
    DESTINATION_API_ENDPOINT: `${localApiUrl}/v4`,
    BOOKING_API_ENDPOINT: `${localApiUrl}/v5`,
    AUTH_API_ENDPOINT: `${localApiUrl}/v3`,
    PRICING_API_ENDPOINT: gatewayExUrl,
    SITEMAP_ENDPOINT: 'http://s3.amazonaws.com/config.skylark.com/sitemaps',
  },
  staging: {
    CONFIG_API_ENDPOINT: `${apiUrl}/v4`,
    CONTENT_API_ENDPOINT: `${apiUrl}/v3`,
    DEALS_API_ENDPOINT: `${apiUrl}/v3`,
    COLLECTIONS_API_ENDPOINT: `${apiUrl}/v4`,
    HOTEL_API_ENDPOINT: `${apiUrl}/v5`,
    SEARCH_API_ENDPOINT: `${apiUrl}/v4`,
    DESTINATION_API_ENDPOINT: `${apiUrl}/v4`,
    BOOKING_API_ENDPOINT: `${gatewayApiUrl}/v5`,
    AUTH_API_ENDPOINT: `${gatewayApiUrl}/v3`,
    PRICING_API_ENDPOINT: gatewayExUrl,
    SITEMAP_ENDPOINT: 'http://s3.amazonaws.com/config.skylark.com/sitemaps',
  },
  production: {
    CONFIG_API_ENDPOINT: `${apiUrl}/v4`,
    CONTENT_API_ENDPOINT: `${apiUrl}/v3`,
    DEALS_API_ENDPOINT: `${apiUrl}/v3`,
    COLLECTIONS_API_ENDPOINT: `${apiUrl}/v4`,
    HOTEL_API_ENDPOINT: `${apiUrl}/v5`,
    SEARCH_API_ENDPOINT: `${apiUrl}/v4`,
    DESTINATION_API_ENDPOINT: `${apiUrl}/v4`,
    BOOKING_API_ENDPOINT: `${gatewayApiUrl}/v5`,
    AUTH_API_ENDPOINT: `${gatewayApiUrl}/v3`,
    PRICING_API_ENDPOINT: gatewayExUrl,
    SITEMAP_ENDPOINT: 'http://s3.amazonaws.com/config.skylark.com/sitemaps',
  },
};

const getCurrentEnvironment = () =>
  // const environmentMap = {
  //   'skylark.com': 'production',
  //   'www.skylark.com': 'production',
  //   'staging.skylark.com': 'staging',
  //   development: 'development'
  // };
  // return environmentMap[window.location.hostname] || environmentMap.development;
  'development';
const currentEnvironment = getCurrentEnvironment();
const contentEndpoints = contentEndpoint[currentEnvironment];
// const pricingEndpoints = pricingEndpoint[currentEnvironment];

export const { CONFIG_API_ENDPOINT } = contentEndpoints;
export const { CONTENT_API_ENDPOINT } = contentEndpoints;
export const { DEALS_API_ENDPOINT } = contentEndpoints;
export const { COLLECTIONS_API_ENDPOINT } = contentEndpoints;
export const { HOTEL_API_ENDPOINT } = contentEndpoints;
export const { SEARCH_API_ENDPOINT } = contentEndpoints;
export const { DESTINATION_API_ENDPOINT } = contentEndpoints;
export const { BOOKING_API_ENDPOINT } = contentEndpoints;
export const { AUTH_API_ENDPOINT } = contentEndpoints;
export const { PRICING_API_ENDPOINT } = contentEndpoints;
export const { SITEMAP_ENDPOINT } = contentEndpoints;

export const BLOG_ENDPOINT = 'https://inspire.skylark.com';

export const CLOUDFRONT_ENDPOINT = 'https://d2q9bdd302n972.cloudfront.net'; //'http://mvp.larktravel.com.s3.amazonaws.com';

export const requestHeaders = { headers: { 'Content-Type': 'application/json' } };
